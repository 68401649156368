import RundownOutputHeader from '../../components/rundown/output/RundownOutputHeader.jsx'
import RundownOutputBody from '../../components/rundown/output/RundownOutputBody.jsx'
import RundownOutputControls from '../../components/rundown/output/RundownOutputControls.jsx'
import { useOutletContext, useSearchParams } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { useState, useCallback, useMemo, useEffect } from 'react'
import _debounce from 'lodash/debounce'
import { BACKGROUND_COLOR, BLACK, HEADER_POSITION, HORIZONTAL, MIRROR, OFF, TOP, VERTICAL } from '../../constants/outputConfigTypes.js'
import NoSleep from 'nosleep.js'
import _keyBy from 'lodash/keyBy.js'
import _find from 'lodash/find.js'
import { Helmet } from 'react-helmet'
import { useAtomValue } from 'jotai'
import { momentAtom } from '../../store/moment.store.js'
import { timestampsAtom } from '../../store/timestamps.store.js'

export default function RundownOutput () {
  const {
    rundown,
    runner,
    cues,
    cells,
    columns,
    event,
  } = useOutletContext()
  const [ searchParams ] = useSearchParams()
  const [controlsVisible, setControlsVisible] = useState(false)
  const debouncedSetControlsVisible = useCallback(_debounce(setControlsVisible, 1000), [])

  const timestamps = useAtomValue(timestampsAtom)
  const moment = useAtomValue(momentAtom)

  function onMouseMove () {
    if (!controlsVisible) setControlsVisible(true)
    debouncedSetControlsVisible(false)
  }

  const configHeaderPosition = searchParams.get(HEADER_POSITION) || TOP
  const configMirror = searchParams.get(MIRROR) || OFF
  const [mirrorVertically, setMirrorVertically] = useState(configMirror?.includes(VERTICAL))
  const [mirrorHorizontally, setMirrorHorizontally] = useState(configMirror?.includes(HORIZONTAL))
  const visibleColumnId = searchParams.get('column')

  const filteredCells = useMemo(() => {
    const filtered = cells.filter((cell)=> cell.columnId === visibleColumnId)
    return _keyBy(filtered, 'cueId')
  }, [visibleColumnId, cells])

  const visibleColumn = useMemo(()=> _find(columns, {id: visibleColumnId}), [visibleColumnId, columns])

  // Remove the default body BG colour and adds the users chosen BG colour
  const configBackgroundColor = searchParams.get(BACKGROUND_COLOR) || BLACK
  useEffect(() => {
    document.body.classList.remove('bg-black')
    document.body.style.backgroundColor = configBackgroundColor
  }, [])

  return (
    <main
      className={[
        'relative flex h-screen transition-transform max-h-[100svh]',
        (mirrorVertically && '-scale-y-100'),
        (mirrorHorizontally && '-scale-x-100'),
        configHeaderPosition === TOP ? 'flex-col' : 'flex-col-reverse',
      ].join(' ')}
      onMouseMove={onMouseMove}
      onTouchMove={onMouseMove}
    >
      <Helmet>
        <title>{rundown.name} | Output</title>
      </Helmet>
      {/* Header */}
      <header
        className="shrink-0 grow-0"
      >
        <RundownOutputHeader
          rundown={rundown}
          moment={moment}
          timestamps={timestamps}
          event={event}
        />
      </header>

      {/* Body */}
      <div
        className="grow overflow-y-auto overflow-x-hide"
      >
        <RundownOutputBody
          rundown={rundown}
          cues={cues}
          timestamps={timestamps}
          runner={runner}
          moment={moment}
          cells={filteredCells}
          visibleColumn={visibleColumn}
          controlsVisible={controlsVisible}
        />
      </div>

      {/* Overlay Controls */}
      {controlsVisible && (
        <div
          className="fixed right-3 bottom-3"
        >
          <RundownOutputControls
            onToggleFullscreen={toggleFullscreen}
            mirrorVertically={mirrorVertically}
            onMirrorVertically={() => setMirrorVertically(!mirrorVertically)}
            mirrorHorizontally={mirrorHorizontally}
            onMirrorHorizontally={() => setMirrorHorizontally(!mirrorHorizontally)}
          />
        </div>
      )}
    </main>
  )
}

const noSleep = new NoSleep()

function toggleFullscreen () {
  if (!document.fullscreenElement) {
    // If the document is not currently in fullscreen mode
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) { // Firefox
      document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
      document.documentElement.webkitRequestFullscreen()
    } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
      document.documentElement.msRequestFullscreen()
    }
    noSleep.enable()
  } else {
    // If the document is already in fullscreen mode, exit fullscreen
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
    noSleep.disable()
  }
}
