import DashboardWrapper from './DashboardWrapper'
import { useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { useOutletContext, useParams } from 'react-router-dom'
import { getTransactions } from '../../firestore'
import UpgradeMessage from '../UpgradeMessage'
import Button from '../Button'
import CancelSubscriptionModal from '../modal/CancelSubscriptionModal'

export default function Billing() {
  const {
    plan,
  } = useOutletContext()

  return (
    <DashboardWrapper title='Billing'>
      {
        plan ?
          <PlanBreakdown plan={plan}/>
          :
          'You are not currently on a paid plan.'
      }
    </DashboardWrapper>
  )
}

const PlanBreakdown = ({ plan }) => {
  const { teamId } = useParams()
  const [showTransactions, setShowTransactions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transactions, setTransactions] = useState()
  const [showCancelModal, setShowCancelModal] = useState(false)

  const handleGetTransactions = async () => {
    if(showTransactions) return setShowTransactions(false)
    setLoading(true)
    const { data } = await getTransactions(plan.transactionIds.join(','))

    setTransactions(data)
    setLoading(false)
    return setShowTransactions(true)
  }

  return (
    <>
      <div className={['p-4 flex gap-4 bg-gray-100/5 border border-gray-800', loading && 'bg-animated from-gray-900 to-gray-800'].join(' ')}>
        <img src={plan.imageUrl} className='mt-1 w-12 h-12 aspect-square' />
        <div className='flex flex-col gap-1 justify-center'>

          {/* Plan name */}
          <p className='font-bold'>You are on the {plan.name} plan</p>

          {/* Trialing badge */}
          {plan.trialing && <p className='px-1 text-[10px] font-bold text-gray-100 bg-gray-700 w-max rounded-sm uppercase'>Free trial</p>}

          {/* Scheduled for cancellation // OR // Next payment date */}
          {plan.scheduledChange?.action === 'cancel' ? (
            <>
              <p className='px-1 text-[10px] font-bold text-gray-100 bg-gray-700 w-max rounded-sm uppercase'>Cancelled</p>
              <p className='text-xs text-gray-300'>Subscription expires: {format(new Date(plan.scheduledChange.effective_at), 'd MMM, yyyy')}</p>
            </>
          ) : (
            plan.billingCycle?.endsAt && <p className='text-xs text-gray-300'>Next payment: {format(new Date(plan.billingCycle.endsAt), 'd MMM, yyyy')}</p>
          )}

          {/* Plan limits */}
          {plan?.limits?.members > 1 && <div className='flex gap-4 '>
            <p className='text-xs'>Your plan allows for {plan?.limits?.members || 1} team members. Want to add more? <a href='mailto:hello@rundownstudio.app' className='underline hover:text-gray-300 cursor-pointer'>Email us here</a>.</p>
          </div>}

          {/* Transactions link and list */}
          {plan.transactionIds.length > 0 && <button className='mt-2 text-left text-xs underline text-gray-400' onClick={handleGetTransactions}>View transactions</button>}
          <div className={['grid text-xs gap-2', showTransactions && 'pt-4'].join(' ')}>
            {showTransactions && transactions?.map((t)=>{
              return <Transaction key={t.id} transaction={t} />
            })}
          </div>
        </div>
        <span className='flex-1'></span>
        <Button
          text='Cancel'
          onClick={() => setShowCancelModal(true)}
          colour='dark'
          disabled={plan.scheduledChange?.action === 'cancel'}
          toolTip={plan.scheduledChange?.action === 'cancel' ? 'Your subscription is already scheduled for cancelation' : ''}
          showTooltipWhenDisabled={true}
        />
      </div>

      {showCancelModal && <CancelSubscriptionModal open={showCancelModal} setOpen={setShowCancelModal} plan={plan} teamId={teamId} />}


      {!plan.subscriptionId &&
        <UpgradeMessage message='Upgrade your account to add more features and team members.'/>
      }
    </>
  )
}

PlanBreakdown.propTypes = {
  plan: PropTypes.object,
}

const billingApiBase = import.meta.env.VITE_BILLING_FUNCTION_BASE

const Transaction = ({ transaction }) =>{
  return (
    <div className='flex gap-4'>
      <p className='font-semibold'>{format(new Date(transaction.billed_at), 'd MMM, yyyy')}</p>
      <p>{(transaction.details.totals.grand_total / 100).toLocaleString('en-US', {style: 'currency', currency: transaction.details.totals.currency_code})}</p>
      <a href={`${billingApiBase}/transactions/${transaction.id}/invoice`} className='underline'>Download invoice</a>
    </div>
  )
}


Transaction.propTypes = {
  transaction: PropTypes.object,
}
