import PropTypes from 'prop-types'
import EditableInput from '../EditableInput.jsx'
import { CUE_TYPE_CUE, CUE_TYPE_HEADING, CUE_TYPE_GROUP } from '../../constants/cueTypes.js'

const editableTitleDefaults = {
  [CUE_TYPE_CUE]: 'Title',
  [CUE_TYPE_HEADING]: 'Heading',
  [CUE_TYPE_GROUP]: 'Group name',
}

export default function CueItemTitle ({
  className,
  cue,
  onUpdateCue,
  readonly = false,
  collapsed,
  childrenCues,
}) {
  return (
    <div className="h-full w-full flex flex-col items-start justify-center px-4">
      <EditableInput
        className={className}
        value={cue.title}
        placeholder={editableTitleDefaults[cue.type]}
        onChange={(title) => onUpdateCue({ title })}
        disabled={readonly}
      />
      {(collapsed && childrenCues.length > 0) && <div className='text-sm opacity-50'>{childrenCues.length} hidden</div>}
    </div>
  )
}

CueItemTitle.propTypes = {
  className: PropTypes.string,
  cue: PropTypes.object.isRequired,
  onUpdateCue: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  collapsed: PropTypes.bool,
  childrenCues: PropTypes.array,
}
