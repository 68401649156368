import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { teamsByUid } from './firestore.js'
import { updateUserId } from './sentry.js'
import commitUTMParams from './utils/commitUTMParams.js'
import * as GtagService from './utils/GtagService.js'
import * as PaddleService from './utils/PaddleService.js'
import { getFirebaseConfig } from './utils/getFirebaseConfig.js'

// Initialize Firebase
const firebaseConfig = getFirebaseConfig()
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)

let currentUser = null
let userPromise = null

/**
 * Initialize the auth state listener.
 * Makes sure the onAuthStateChanged listener is only attached once per pageload.
 *
 * Note: Initiate Gtag when we have a user, this way we know that they accepted the privacy policy.
 *       Same for Paddle to activate Paddle Retain and Engagement.
 *
 * @idempotent
 * @return {Promise<FirebaseUser>}
 */
function _initializeAuthStateListener () {
  if (userPromise) return userPromise

  userPromise = new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      currentUser = user
      updateUserId(user?.uid)
      if (user) {
        GtagService.init(firebaseConfig.measurementId, firebaseConfig.adsId)
        PaddleService.init()
      }
      if (user?.email) commitUTMParams()
      resolve(user)
    })
  })

  return userPromise
}

/**
 * Get the current user or wait for auth to initialize.
 * @idempotent
 * @return {Promise<FirebaseUser>}
 */
export async function getUser () {
  if (currentUser) return auth.currentUser
  return _initializeAuthStateListener()
}

/**
 * Get a user's teams
 * @return {Promise<Team[]>}
 */
export async function getUserTeams () {
  const user = await getUser()
  const { data: teams } = await teamsByUid(user?.uid)
  return teams
}

// Initialize the auth state listener immediately
_initializeAuthStateListener()
