import { useAtomValue } from 'jotai'
import PropTypes from 'prop-types'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store'
import { getPreviousCueAtom } from '../../../store/rundown.store'
import { CueStartMode } from '@rundown-studio/types'
import { addMilliseconds } from 'date-fns'
import removeMilliseconds from '../../../utils/removeMilliseconds'
import { formatDurationHuman } from '../../../utils/formatTime'
import { isSameDay } from '@rundown-studio/timeutils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faWarning } from '@fortawesome/free-solid-svg-icons'

export default function BeweenCuesIndicator ({ cue, timezone = 'UTC' }) {
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const timestamp = getTimestampByCueId(cue.id)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)
  const previousCue = getPreviousCueById(cue.id)
  const previousCueTimestamp = getTimestampByCueId(previousCue?.id)

  if (!timestamp || !previousCueTimestamp) return null

  const previousEndTime = addMilliseconds(previousCueTimestamp.actual.start, previousCueTimestamp.actual.duration)
  const differenceMs = removeMilliseconds(timestamp.actual.start).getTime() - removeMilliseconds(previousEndTime).getTime()

  let text = ''
  let color = ''
  let icon = null
  const timeDiffStr = formatDurationHuman(differenceMs)

  if (cue.startMode === CueStartMode.FIXED) {
    if (differenceMs > 0) {
      // GAP
      text = `Gap of ${timeDiffStr}`
      color = 'text-green-800'
      icon = faClock
    } else if (differenceMs < 0) {
      // OVERLAP
      text = `Overlap by ${timeDiffStr}`
      color = 'text-red-800'
      icon = faWarning
    }
  }

  if (!isSameDay(timestamp.actual.start, previousCueTimestamp.actual.start, { timezone })) {
    if (!text) return <div className="leading-5 text-xs my-0.5 text-gray-500">Crossing midnight</div>
    else text = `${text} and crossing midnight`
  }

  if (!text) return null

  return (
    <div className='leading-5 text-xs my-0.5 w-fit text-gray-500'>
      <span className={color}>{icon && <FontAwesomeIcon icon={icon} />}</span> {text}
    </div>
  )
}

BeweenCuesIndicator.propTypes = {
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
}