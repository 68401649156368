import PropTypes from 'prop-types'
import CueTimingWrapper from './CueTimingWrapper.jsx'
import { Popover, PopoverContent, PopoverTrigger } from '../../interactives/Popover'
import { applyDate, formatTimeOfDay, formatTimezone, moveAfterWithTolerance } from '@rundown-studio/timeutils'
import { formatDurationHuman } from '../../../utils/formatTime'
import { CUE_OVERLAP_TOLERANCE } from '@rundown-studio/consts'
import TimePopoverContent from './TimePopoverContent'
import DurationPopoverContent from './DurationPopoverContent'
import TopStartTimeComponent from './TopStartTimeComponent'
import { useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store.js'
import { updateRundown, updateRundownCue } from '../../../firestore.js'
import { firstCueIdAtom, getPreviousCueAtom, rundownAtom, setCueAtom } from '../../../store/rundown.store.js'
import { CueStartMode } from '@rundown-studio/types'
import { CueRunState } from '@rundown-studio/utils'
import { momentAtom } from '../../../store/moment.store.js'
import getRelativeDayString from '../../../utils/getRelativeDayString.js'
import removeMilliseconds from '../../../utils/removeMilliseconds.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import { addMilliseconds } from 'date-fns'

export default function CueTiming ({
  rundownId,
  cue,
  timezone = 'UTC',
  cellStyle,
}) {
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const timestamp = getTimestampByCueId(cue.id)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)
  const previousCue = getPreviousCueById(cue.id)
  const previousCueTimestamp = getTimestampByCueId(previousCue?.id)
  const setCue = useSetAtom(setCueAtom)
  const [rundown, setRundown] = useAtom(rundownAtom)
  const firstCueId = useAtomValue(firstCueIdAtom)
  const isFirstCue = cue.id === firstCueId
  const moment = useAtomValue(momentAtom)

  const [internalStartTime, setInternalStartTime] = useState(timestamp?.actual.start)
  const [internalDuration, setInternalDuration] = useState(timestamp?.actual.duration)
  const [internalStartMode, setInternalStartMode] = useState(cue.startMode || CueStartMode.FLEXIBLE)
  const [loadingStartTime, setLoadingStartTime] = useState(false)
  const [loadingDuration, setLoadingDuration] = useState(false)

  const readOnly = cue.locked || RundownToken.access !== ACCESS_WRITE

  function moveAndSetInternalStartTime (startTime) {
    if (isFirstCue) return setInternalStartTime(startTime)
    const movedTime = moveAfterWithTolerance(new Date(startTime), previousCueTimestamp.actual.start, internalStartMode === CueStartMode.FIXED ? CUE_OVERLAP_TOLERANCE : 0, { timezone })
    setInternalStartTime(movedTime)
  }

  function updateStartTime () {
    if (new Date(internalStartTime).getTime() === timestamp.actual.start.getTime() && cue.startMode === internalStartMode) return
    setLoadingStartTime(true)
    handleUpdateStartTime({ startTime: internalStartTime, startMode: internalStartMode })
      .then(() => setLoadingStartTime(false))
  }

  async function handleUpdateStartTime ({ startTime, startMode }) {
    if (isFirstCue) {
      const { data } = await updateRundown(rundownId, { startTime: applyDate(startTime, rundown.startTime, { timezone }) })
      setRundown(data)
    } else {
      if (startMode === CueStartMode.FLEXIBLE) {
        const calculatedDuration = Math.max(0, startTime - previousCueTimestamp.actual.start)
        const { data: previousCueData } = await updateRundownCue(rundownId, previousCue.id, { duration: calculatedDuration })
        setCue(previousCueData)
        const { data: currentCueData } = await updateRundownCue(rundownId, cue.id, { startMode })
        setCue(currentCueData)
      } else {
        const { data } = await updateRundownCue(rundownId, cue.id, { startTime, startMode })
        setCue(data)
      }
    }
  }

  function updateDuration () {
    if (internalDuration === timestamp.actual.duration) return
    setLoadingDuration(true)
    handleUpdateDuration(internalDuration)
      .then(() => setLoadingDuration(false))
  }

  async function handleUpdateDuration (duration) {
    const { data } = await updateRundownCue(rundownId, cue.id, { duration })
    setCue(data)
  }

  function getStartTimeText () {
    if (!internalStartTime) return ''
    const original = new Date(timestamp.original.start.setMilliseconds(0))
    const actual = new Date(internalStartTime)
    if (!moment) {
      const startMode = isFirstCue ? '' : internalStartMode === CueStartMode.FIXED ? 'hard' : 'soft'
      const dateString = formatTimeOfDay(actual, { timezone, seconds: 'nonzero'})
      const dayString = getRelativeDayString(actual, timezone)
      const tzString = formatTimezone(timezone, 'abbr')
      return `Planned ${startMode} start at ${dateString} ${dayString} (${tzString}).`
    }
    let intro = ''
    if ([CueRunState.CUE_PAST, CueRunState.CUE_ACTIVE].includes(timestamp.state)) {
      intro = 'Started'
    }
    if ([CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state)) {
      intro = 'Expected to start'
    }
    const dateString = formatTimeOfDay(actual, { timezone, seconds: 'nonzero'})
    const dayString = getRelativeDayString(actual, timezone)
    const tzString = formatTimezone(timezone, 'abbr')
    let diffString = ''
    if (original.getTime() === actual.getTime()) {
      diffString = 'right on time'
    }
    const diffAmountString = formatDurationHuman(original.getTime() - actual.getTime())
    if (original.getTime() < actual.getTime()) {
      diffString = `${diffAmountString} later than planned`
    } else if (original.getTime() > actual.getTime()) {
      diffString = `${diffAmountString} earlier than planned`
    }
    return `${intro} at ${dateString} ${dayString} (${tzString}), ${diffString}.`
  }

  function getDurationText () {
    const original = timestamp.original.duration
    const actual = timestamp.actual.duration
    if (timestamp.state === CueRunState.CUE_ACTIVE && actual > original) {
      return `Running ${formatDurationHuman(moment.total - moment.left - 1000)} total.`
    }
    if (timestamp.state === CueRunState.CUE_PAST) {
      const originalDurationString = formatDurationHuman(original)
      const diffDurationString = formatDurationHuman(original - actual - 1000)
      if (original == actual) {
        return `Ran exactly ${originalDurationString}`
      } else {
        return `Ran ${diffDurationString} ${original < actual ? 'over' : 'under'} the expected ${originalDurationString}.`
      }
    }
  }

  if (!timestamp) return null

  return <>
    <CueTimingWrapper
      cellStyle={cellStyle}
      top={(
        <TopStartTimeComponent
          startMode={cue.startMode || CueStartMode.FLEXIBLE}
          text={removeMilliseconds(timestamp.original.start).getTime() !== removeMilliseconds(timestamp.actual.start).getTime() ? formatTimeOfDay(timestamp.original.start, { timezone, seconds: 'nonzero'}) : ''}
          strikethrough={true}
        />
      )}
      center={(
        <Popover
          onOpen={() => {
            moveAndSetInternalStartTime(timestamp.actual.start)
            setInternalStartMode(cue.startMode || CueStartMode.FLEXIBLE)
          }}
          onOutsideClick={updateStartTime}
          closeWithEnter={true}
          onEnterKey={updateStartTime}
        >
          <PopoverTrigger asChild={true} disabled={readOnly}>
            <button
              type='button'
              className={[
                'w-24 leading-6 rounded bg-gray-900 hover:enabled:bg-gray-800',
                loadingStartTime ? 'bg-animated from-gray-800 to-gray-600' : '',
              ].join(' ')}
            >
              <p className={[
                'text-center tabular-nums',
                removeMilliseconds(timestamp.original.start) > removeMilliseconds(timestamp.actual.start) ? 'text-green-600' : '',
                removeMilliseconds(timestamp.original.start) < removeMilliseconds(timestamp.actual.start) ? 'text-red-600' : '',
              ].join(' ')}>
                {formatTimeOfDay(timestamp.actual.start, { timezone, seconds: [CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state) && moment?.left < 0 ? 'always' : 'nonzero'})}
              </p>
            </button>
          </PopoverTrigger>
          <PopoverContent
            onSave={updateStartTime}
          >
            <TimePopoverContent
              startTime={internalStartTime}
              timezone={timezone}
              startMode={internalStartMode}
              handleStartTimeChange={moveAndSetInternalStartTime}
              handleStartModeChange={(startMode) => {
                setInternalStartMode(startMode)
                setInternalStartTime(addMilliseconds(previousCueTimestamp.actual.start, previousCueTimestamp.actual.duration))
              }}
              text={getStartTimeText()}
              disabled={[CueRunState.CUE_PAST, CueRunState.CUE_ACTIVE].includes(timestamp.state)}
              isFirstCue={isFirstCue}
            />
          </PopoverContent>
        </Popover>
      )}
    />
    <CueTimingWrapper
      cellStyle={cellStyle}
      top={
        <TopStartTimeComponent
          strikethrough={timestamp.state === CueRunState.CUE_PAST || [CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state) && timestamp.original.duration !== timestamp.actual.duration}
          text={[CueRunState.CUE_ACTIVE, CueRunState.CUE_PAST].includes(timestamp.state)
            ? formatDurationHuman(cue.duration)
            : timestamp.original.duration !== timestamp.actual.duration
              ? formatDurationHuman(timestamp.original.duration)
              : ''}
        />
      }
      center={(
        <Popover
          onOpen={() => setInternalDuration(timestamp.actual.duration)}
          onOutsideClick={updateDuration}
          closeWithEnter={true}
          onEnterKey={updateDuration}
        >
          <PopoverTrigger asChild={true} disabled={readOnly}>
            <button
              type='button'
              className={[
                'w-24 leading-6 rounded bg-gray-900 hover:enabled:bg-gray-800',
                loadingDuration ? 'bg-animated from-gray-800 to-gray-600' : '',
              ].join(' ')}
            >
              <p className={[
                'text-center tabular-nums',
                timestamp.state === CueRunState.CUE_PAST && cue.duration > timestamp.actual.duration ? 'text-green-600' : '',
                timestamp.state === CueRunState.CUE_PAST && cue.duration < timestamp.actual.duration ? 'text-red-600' : '',
                timestamp.state === CueRunState.CUE_ACTIVE && moment?.left < -1000 ? 'text-red-600' : '',
              ].join(' ')}>
                {[
                  timestamp.state === CueRunState.CUE_ACTIVE && moment?.left < -1000 ? '+' : '',
                  formatDurationHuman(timestamp.state === CueRunState.CUE_ACTIVE ? moment?.left || 0 : timestamp.actual.duration).trim(),
                ].join('')}
              </p>
            </button>
          </PopoverTrigger>
          <PopoverContent
            onSave={updateDuration}
            saveDisabled={timestamp.state === CueRunState.CUE_PAST}
          >
            <DurationPopoverContent
              duration={internalDuration}
              handleDurationChange={setInternalDuration}
              text={getDurationText()}
              disabled={timestamp.state === CueRunState.CUE_PAST}
            />
          </PopoverContent>
        </Popover>
      )}
    />
  </>
}

CueTiming.propTypes = {
  rundownId: PropTypes.string.isRequired,
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  cellStyle: PropTypes.object,
}
